import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { Spin } from "antd";

import { LoadingOutlined } from "@ant-design/icons";
import { RiCloseCircleLine, RiQuestionLine } from "react-icons/ri";
import { BiChevronRight } from "react-icons/bi";
import { GoChevronRight } from "react-icons/go";

import { useTabsTicketsAndPurchases } from "contexts/TabsTicketsAndPurchasesContext";

import { useDisclosure } from "hooks/useDisclosure";
import { Purchase } from "types";

import { Colors } from "constants/colors";

import hasCookiesEnabled from "utils/hasCookiesEnabled";
import { checkUserPlatform } from "utils/plaftformDetect";
import { returnStatusPurchase, purchaseSubtitleFactor } from "utils/purchase";
import { formatPrice } from "utils/formatPrice";
import { returnTypePaymentPurchase } from "utils/purchase";

import {
  Container,
  ContainerItems,
  CardTicket,
  ListAccordion,
  CardShopping,
  ContainerButtons,
  ButtonVisualizationTicket,
  ButtonDownload,
  ContainerHeader,
  ContainerCard,
  ContainerAllItemsMobile,
  ContainerPurchaseDetails,
  PurchaseDetails,
  BadgeMobile,
  BorderNormalPrice,
  BorderSmallPrice,
  ButtonCancelPurchaseMobile,
  ContainerHelp,
  ContainerModal,
  ContainerDetailsShoping,
  ContainerBorderNormalShipping,
  ContainerModalSubtotal,
  ContainerModalStatus,
  ContainerModalTypePayment,
  ContainertModalTotal,
  ModalStyled,
  ListPurchasesItems,
  DivEmpty,
  ContainerModalConfirm,
  ButtonConfirm,
  ButtonClose,
} from "./styles";

import Popup from "containers/Popup";
import { Footer } from "components/Footer";
import { Header } from "components/Header";
import { ListBreadcrumb } from "components/ListBreadcrumb";
import { Pagination } from "components/Pagination";
import { SidebarProfile } from "components/SidebarProfile";
import { Title } from "components/Title";

import headphone_icon from "assets/headphone-icon.svg";
import headphone_gray from "assets/headphone-gray.svg";
import icon_help from "assets/icon-help.svg";
import cart_icon from "assets/cart-icon.svg";
import cancel_confirm from "assets/cancel-confirm.svg";
import TabsTicketsAndPurchases from "../TabTicketAndPurchase";
import { useSearchPurchase } from "hooks/useSearchPurchase";
import { GridSidebar } from "styles/pages/Sidebar";

import { ModalPayment } from "components/ModalPayment";
import { divideBy100 } from "utils/divideBy100";
import { buildMicroRoute } from "utils/urlBuilder";

export default function MyShoppings() {
  const {
    selectedTicketMobile,
    selectedPurchaseMobile,
    closeTicket,
    closePurchase,
  } = useTabsTicketsAndPurchases();

  const [visible, setVisible] = useState(false);
  const [userPlatform, setUserPlatform] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();

  const checkoutId = searchParams.get("checkout_id") ?? "";

  const navigate = useNavigate();

  const [selectedPurchase, setSelectedPurchase] = useState<Purchase>();

  const {
    showModal: showModalPayment,
    handleOk: handleOkPayment,
    handleCancel: handleCancelPayment,
    isModalVisible: isModalPaymentVisible,
  } = useDisclosure();

  const {
    handleCancel: handleCancelConfirm,
    handleOk: handleOkConfirm,
    isModalVisible: isModalVisibleConfirm,
    showModal: showModalConfirm,
  } = useDisclosure();

  const { data, onChange, page, isValidating } = useSearchPurchase();

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 38, color: Colors.primary100 }} spin />
  );

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showModalPurchasing = (purchase: Purchase) => {
    setSelectedPurchase(purchase);
    showModal();
  };

  const handleCookies = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (checkoutId) {
      showModalPayment();

      return;
    }

    handleOkPayment();
  }, [checkoutId, handleOkPayment, showModalPayment]);

  useEffect(() => {
    !hasCookiesEnabled() && setVisible(true);
    setUserPlatform(checkUserPlatform());
  }, [setVisible, userPlatform]);

  const handleOpenCheckout = useCallback(
    (checkoutId) => {
      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        checkout_id: String(checkoutId),
      });
    },
    [searchParams, setSearchParams]
  );

  const handleCloseModalPayment = () => {
    searchParams.delete("checkout_id");

    setSearchParams({
      ...Object.fromEntries([...searchParams]),
    });

    handleCancelPayment();
  };

  const metaTags = {
    title: `Minhas compras - ${process.env.REACT_APP_EVENTX_META_TITLE_NAME}`,
    description: process.env.REACT_APP_EVENTX_META_DESCRIPTION,
    noIndex: true,
  };

  return (
    <>
      {!selectedTicketMobile && !selectedPurchaseMobile ? (
        <Header isProfile metaTags={metaTags} />
      ) : null}

      {selectedTicketMobile && !selectedPurchaseMobile ? (
        <Header isProfile customBack={closeTicket} metaTags={metaTags} />
      ) : null}

      {selectedPurchaseMobile && !selectedTicketMobile ? (
        <Header isProfile customBack={closePurchase} metaTags={metaTags} />
      ) : null}

      <Container>
        <ContainerItems>
          <div className="breadcrumb">
            <ListBreadcrumb
              data={[
                { name: "Home", link: "/" },
                { name: "Perfil", link: "/" },
              ]}
            />
          </div>

          <Title />

          <GridSidebar>
            <SidebarProfile />

            <CardTicket>
              <ContainerHeader>
                <h2>Minhas Compras</h2>
              </ContainerHeader>

              <ListAccordion>
                <Spin spinning={isValidating} indicator={antIcon} />

                {data?.results ? <h2 className="history">HISTÓRICO</h2> : null}

                {data?.results.length === 0 ? (
                  <DivEmpty>
                    <h2 className="empty">Não há nada por aqui :(</h2>

                    <p className="empty">Não há Compras</p>
                  </DivEmpty>
                ) : null}

                {data?.results &&
                  data?.results.map((purchase) => {
                    return (
                      <ContainerCard key={purchase._id}>
                        <h3 className="date">{purchase?.formattedDate}</h3>
                        <CardShopping>
                          <div
                            className="content"
                            onClick={() => showModalPurchasing(purchase)}
                          >
                            <div className="container-texts">
                              <div className="container-image">
                                {purchase?.event_id !== null ? (
                                  <img src={purchase?.event_id?.thumb} alt="" />
                                ) : null}
                              </div>

                              <div className="texts">
                                {purchase?.event_id !== null ? (
                                  <h2>{purchase?.event_id?.name}</h2>
                                ) : null}
                                <p>{purchaseSubtitleFactor(purchase)}</p>
                              </div>
                            </div>

                            <GoChevronRight size={32} color={Colors.purple10} />
                          </div>

                          {/* <ListPurchasesItems>
                            {purchase.purchases.map((purchaseItem, index) => (
                              <div key={index} className="ticket-quantity">
                                <div className="badge">
                                  <span>{purchaseItem.quantity}</span>
                                </div>

                                <h2>{purchaseItem.name}</h2>
                              </div>
                            ))}
                          </ListPurchasesItems> */}

                          <ContainerButtons>
                            <Link to="/faq">
                              <ButtonDownload isAfter={purchase?.isAfter}>
                                <img
                                  className="ticket"
                                  src={icon_help}
                                  alt="Logo"
                                  width="18px"
                                  height="18px"
                                />
                                <span>Ajuda</span>
                              </ButtonDownload>
                            </Link>

                            {purchase?.payment_status === "PENDING" ? (
                              <ButtonVisualizationTicket
                                onClick={() =>
                                  handleOpenCheckout(purchase?._id)
                                }
                              >
                                <img
                                  className="ticket"
                                  src={cart_icon}
                                  width="18px"
                                  height="18px"
                                  alt="cart"
                                />
                                <span>Visualizar Checkout</span>
                              </ButtonVisualizationTicket>
                            ) : null}
                          </ContainerButtons>
                        </CardShopping>
                      </ContainerCard>
                    );
                  })}
              </ListAccordion>

              <Pagination
                totalCountOfRegisters={data?.count}
                currentPage={page}
                onPageChange={onChange}
                registersPerPage={5}
              />
            </CardTicket>
          </GridSidebar>

          <ContainerAllItemsMobile>
            {!selectedPurchaseMobile ? (
              <div className="header">
                <h1>Minhas compras</h1>
                <RiQuestionLine size={24} color={Colors.gray30} />
              </div>
            ) : null}

            <TabsTicketsAndPurchases />
          </ContainerAllItemsMobile>
        </ContainerItems>

        <ModalStyled
          width={642}
          visible={isModalVisibleConfirm}
          onOk={handleOkConfirm}
          onCancel={handleCancelConfirm}
          closeIcon={null}
          footer={null}
        >
          <ContainerModalConfirm>
            <img
              src={cancel_confirm}
              width={189}
              height={189}
              alt="confirm-cancel"
            />

            <div className="texts-confirm">
              <h3>Deseja cancelar a compra?</h3>

              <p>
                Talvez falar com o vendendor possa resolver seu problema, tente
                fazer isso antes de cancelar a compra de vez!
              </p>
            </div>

            <div className="buttons">
              <ButtonClose onClick={handleOkConfirm}>
                <span>Voltar</span>
              </ButtonClose>

              <ButtonConfirm
                onClick={() => {
                  handleOkConfirm();
                  navigate(`/profile/terms-cancel/${selectedPurchase?._id}`);
                }}
              >
                <span>Sim</span>
              </ButtonConfirm>
            </div>
          </ContainerModalConfirm>
        </ModalStyled>

        <ModalStyled
          width={1000}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          closeIcon={
            <RiCloseCircleLine
              style={{ marginTop: "14px", marginRight: "34px" }}
              size={43}
              color={Colors.black40}
            />
          }
          footer={null}
        >
          <ContainerModal>
            <div className="title">
              <h2 className="title">Detalhes da compra</h2>
            </div>
            <div className="container-web">
              <ContainerDetailsShoping>
                <div className="header-details">
                  <div className="content">
                    {selectedPurchase?.event_id &&
                    selectedPurchase?.event_id?.photo ? (
                      <img
                        className="ticket"
                        src={selectedPurchase?.event_id.photo}
                        alt="Logo"
                      />
                    ) : null}

                    <div className="column">
                      <h2>{selectedPurchase?.event_id.name}</h2>

                      <span>
                        Realizada ás {selectedPurchase?.formattedHour} .{" "}
                        {selectedPurchase?.formattedDay}
                      </span>

                      <a
                        style={{ textDecoration: "none" }}
                        href={buildMicroRoute(
                          `/${selectedPurchase?.event_id?.nickname}`
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Ver evento
                      </a>
                    </div>
                  </div>

                  <div className="badge-content">
                    <h3>ID da compra: {selectedPurchase?._id}</h3>{" "}
                  </div>
                </div>

                {/* {selectedPurchase?.purchases?.length
                  ? selectedPurchase?.purchases.map((purchase) => (
                      <ContainerBorderNormalShipping key={purchase.id}>
                        <div className="content-border">
                          <h2 className="title-border">{purchase.name}</h2>

                          <div className="row">
                            <strong>{formatPrice(purchase.total * 100)}</strong>
                          </div>
                        </div>

                        <div className="badge-quantity">
                          <h4>{purchase.quantity}x</h4>
                        </div>
                      </ContainerBorderNormalShipping>
                    ))
                  : null} */}

                <ContainerModalTypePayment>
                  <h3 className="type">Pagamento</h3>

                  <div className="t-payment">
                    <span className="price">
                      {returnTypePaymentPurchase(
                        selectedPurchase?.payment_type ?? ""
                      )}
                    </span>
                  </div>
                </ContainerModalTypePayment>

                <ContainerModalSubtotal>
                  <div className="row-sub">
                    <h3>Subtotal</h3>

                    <span>
                      {selectedPurchase?.total_wet !== null
                        ? selectedPurchase?.total_wet === 0
                          ? "R$ 0,00"
                          : formatPrice(
                              divideBy100(selectedPurchase?.total_wet ?? 0)
                            )
                        : "-"}
                    </span>
                  </div>

                  <div className="row-sub">
                    <h3>Taxas</h3>

                    <span>
                      {selectedPurchase?.total_fee !== null
                        ? selectedPurchase?.total_fee === 0
                          ? "R$ 0,00"
                          : formatPrice(
                              divideBy100(selectedPurchase?.total_fee ?? 0)
                            )
                        : "-"}
                    </span>
                  </div>
                </ContainerModalSubtotal>

                <ContainertModalTotal>
                  <h3>Valor total:</h3>

                  <span>
                    {selectedPurchase?.total !== null
                      ? selectedPurchase?.total === 0
                        ? "R$ 0,00"
                        : formatPrice(divideBy100(selectedPurchase?.total ?? 0))
                      : "-"}
                  </span>
                </ContainertModalTotal>

                <ContainerModalStatus>
                  <div className="row-sub">
                    <h3>Status da compra:</h3>
                    <span>
                      {returnStatusPurchase(
                        selectedPurchase?.payment_status || ""
                      )}
                    </span>
                  </div>
                </ContainerModalStatus>
              </ContainerDetailsShoping>

              {selectedPurchase?.payment_status === "PAID" ? (
                <div className="cancel-purchasing">
                  <button
                    onClick={() => {
                      handleOk();
                      showModalConfirm();
                    }}
                  >
                    <span>Cancelar compra</span>
                  </button>
                </div>
              ) : null}

              <footer className="help-you">
                <h1>PRECISA DE AJUDA?</h1>

                <Link to="/faq">
                  <div className="drop">
                    <div className="first">
                      <div className="badge">
                        <img
                          src={headphone_icon}
                          width={25}
                          height={26}
                          alt="headphone-icon"
                        />
                      </div>
                      <h2>Suporte</h2>
                    </div>

                    <BiChevronRight color={Colors.primary100} size={34} />
                  </div>
                </Link>
              </footer>
            </div>

            <div className="container-mobile">
              <ContainerPurchaseDetails>
                <div className="header">
                  <h1>Detalhes da compra</h1>
                  <RiQuestionLine size={21} color={Colors.gray30} />
                </div>

                <PurchaseDetails>
                  <div className="shopping-event">
                    <div className="container">
                      <div className="container-image"></div>

                      <div className="content">
                        {/* <h3>{selectedPurchase?.event.name}</h3> */}

                        <span>
                          Realizada ás {selectedPurchase?.formattedHour} .{" "}
                          {selectedPurchase?.formattedDay}
                        </span>
                      </div>
                    </div>

                    {/* <div className="view-event">
                      <a
                        style={{ textDecoration: "none" }}
                        href={buildMicroRoute(
                          `/${selectedPurchase?.event?.nickname}`
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <button>Ver evento</button>
                      </a>
                    </div> */}
                  </div>

                  <BadgeMobile>
                    <h4>CÓDIGO da compra kkk: {selectedPurchase?._id}</h4>
                  </BadgeMobile>

                  <div className="borders">
                    {/* {selectedPurchase?.purchases.length
                      ? selectedPurchase?.purchases.map((purchase) => (
                          <BorderNormalPrice key={purchase.id}>
                            <div className="column">
                              <h3>{purchase.name}</h3>

                              <strong>R$ {purchase.total.toFixed(2)}</strong>
                            </div>

                            <div className="quantity">
                              <h4>x{purchase.quantity}</h4>
                            </div>
                          </BorderNormalPrice>
                        ))
                      : null} */}

                    <BorderSmallPrice>
                      <h2 className="title-card">Pagamento</h2>

                      {/* <div className="card">
                        <div className="t-payment">
                          <span className="price">
                            {returnTypePaymentPurchase(
                              selectedPurchase?.type_payment ?? ""
                            )}
                          </span>
                        </div>
                      </div> */}
                    </BorderSmallPrice>

                    <BorderSmallPrice>
                      <h2 className="title-card">STATUS</h2>

                      {/* <div className="card">
                        <div className="t-payment">
                          <span className="price">
                            {returnStatusPurchase(
                              selectedPurchase?.purchase_status || ""
                            )}
                          </span>
                        </div>
                      </div> */}
                    </BorderSmallPrice>
                  </div>

                  <div className="prices">
                    <div className="row-price">
                      <h3>Subtotal</h3>

                      <span>
                        {selectedPurchase?.total_wet
                          ? formatPrice(divideBy100(selectedPurchase.total_wet))
                          : "-"}
                      </span>
                    </div>
                    <div className="row-price">
                      <h3>Taxas</h3>
                      <span>
                        {selectedPurchase?.total_fee
                          ? formatPrice(divideBy100(selectedPurchase.total_fee))
                          : "-"}
                      </span>
                    </div>
                  </div>

                  <div className="total-price">
                    <h5>Valor total</h5>
                    <h3>
                      {selectedPurchase?.total
                        ? formatPrice(divideBy100(selectedPurchase.total))
                        : "-"}
                    </h3>
                  </div>

                  <ButtonCancelPurchaseMobile
                    onClick={() => {
                      handleOk();
                      showModalConfirm();
                    }}
                  >
                    <span>Cancelar compra</span>
                  </ButtonCancelPurchaseMobile>

                  <ContainerHelp></ContainerHelp>
                </PurchaseDetails>
              </ContainerPurchaseDetails>

              <footer className="help-you">
                <h1>PRECISA DE AJUDA?</h1>

                <Link to="/faq">
                  <div className="drop">
                    <div className="first">
                      <div className="badge">
                        <img
                          src={headphone_gray}
                          alt="headphone"
                          width={15}
                          height={15}
                        />
                      </div>
                      <h2>Suporte</h2>
                    </div>

                    <BiChevronRight color={Colors.secondary40} size={18} />
                  </div>
                </Link>
              </footer>
            </div>
          </ContainerModal>
        </ModalStyled>
      </Container>

      <ModalPayment
        checkoutId={checkoutId}
        isModalVisible={isModalPaymentVisible}
        handleOk={handleCloseModalPayment}
        handleCancel={handleCloseModalPayment}
      />

      <Footer />

      {visible && <Popup handleVisible={handleCookies} />}
    </>
  );
}

import { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { authClient } from "services/authClient";
import { useMyEvent } from "services/Hook/Event";
import { useSWRConfig } from "swr";
import { EventOrganizer } from "types";

interface EventsInterface {
  results: EventOrganizer[];
  count: number;
}

export function useSearchMyEvent() {
  const [searchParams, setSearchParams] = useSearchParams();

  const { mutate } = useSWRConfig();

  const name_event = searchParams.get("name_event") ?? "";
  const pageCurrent = searchParams.get("page");
  const [page, setPage] = useState(
    Number(pageCurrent) === 0 ? 1 : Number(pageCurrent)
  );
  const [pageMobile, setPageMobile] = useState(1);

  const [loadingMoreItems, setLoadingMoreItems] = useState(false);
  const [hasMoreItems, setHasMoreItems] = useState(true);

  const { data, partialUrl } = useMyEvent<EventsInterface>(page, name_event);

  const isLoading = !Boolean(data);

  const onChange = useCallback(
    (currentPage: number) => {
      setPage(currentPage);

      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        page: String(currentPage),
      });

      window.scrollTo({ top: 660, behavior: "smooth" });
    },
    [searchParams, setSearchParams]
  );

  const handleLoadMoreItemsMobile = useCallback(() => {
    setPageMobile(pageMobile + 1);

    mutate(
      [partialUrl, 1, name_event],
      async () => {
        setLoadingMoreItems(true);

        const response = await authClient(
          `${partialUrl}?page=${pageMobile + 1}&limit=40`
        );

        const count = response.data.cout;

        const last_page = Math.ceil(count / 40);

        if (pageMobile + 1 === last_page) {
          setHasMoreItems(false);
        }

        setLoadingMoreItems(false);

        const mapEvents = response.data.results.map((event: Event) => ({
          ...event,
          isOpen: false,
        }));

        let newMapEvents = data?.results;

        const mobile = data?.results;

        if (mobile) {
          newMapEvents = [...mobile, ...mapEvents];
        }

        return {
          count: response.data.count,
          results: newMapEvents,
        };
      },
      false
    );
  }, [data?.results, mutate, name_event, pageMobile, partialUrl]);

  return {
    data,
    page,
    loadingMoreItems,
    hasMoreItems,
    handleLoadMoreItemsMobile,
    onChange,
    isLoading,
  };
}

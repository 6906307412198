import { useEffect, useState } from "react";

import { Select, Checkbox, Space } from "antd";

import { SubmitHandler, useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { FaWhatsapp } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { BiPlus } from "react-icons/bi";

import { authClient } from "services/authClient";

import { Colors } from "constants/colors";

import {
  BorderSelect,
  ButtonAddImage,
  Card,
  Container,
  ContainerCheck,
  ContainerCheckConditions,
  ContainerSelect,
  Divider,
  GridInputs,
  LabelSelect,
  LabelTitle,
  SubLabelTitle,
  SubTitle,
  TextTerms,
  Title,
  ButtonNext,
} from "./styles";

import { InputForm } from "components/Form/InputForm";
import { useCreateEvent } from "contexts/CreateEventContext";
import { ButtonPrev, ContainerButtons } from "../../styles";
import { StyledSelect } from "components/ModalConfirmReverse/styles";
import { AddEvent } from "services/Hook/Event";
import { useAuthOrganizer } from "services/Hook/Organizer";
import { errorNotification } from "components/Notification";

const { Option } = Select;

type CreateAboutOrganizerEventFormData = {
  checked_email: boolean;
  checked_whatsapp: boolean;
  support_email: string;
  support_whatsapp: string;
  organizer: number;
  accepted_terms: boolean;
};

const createAboutOrganizerEventFormSchema = yup.object().shape({
  checked_email: yup.boolean().notRequired(),
  checked_whatsapp: yup.boolean().notRequired(),
  organizer: yup.string().required("Organizador obrigatório"),
  support_email: yup
    .string()
    .notRequired()
    .nullable()
    .when("checked_email", {
      is: true,
      then: yup.string().required("Email obrigatório"),
    }),
  support_whatsapp: yup
    .string()
    .notRequired()
    .nullable()
    .when("checked_whatsapp", {
      is: true,
      then: yup.string().required("Whatsapp obrigatório"),
    }),
  accepted_terms: yup.boolean().required(),
});

export function AboutOrganizer() {
  const { event, file } = useCreateEvent();

  const { data } = useAuthOrganizer();

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm<CreateAboutOrganizerEventFormData>({
    resolver: yupResolver(createAboutOrganizerEventFormSchema),
  });
  const [isSubmitting, setIsSubmiting] = useState(false);

  useEffect(() => {
    reset({
      checked_email: event.organizer?.checked_email,
      checked_whatsapp: event.organizer?.checked_whatsapp,
      support_email: event.organizer?.support_email,
      support_whatsapp: event.organizer?.support_whatsapp,
    });
  }, [reset, event.organizer]);

  const handleAddAboutOrganizer: SubmitHandler<
    CreateAboutOrganizerEventFormData
  > = async ({
    organizer,
    support_email,
    support_whatsapp,
    checked_email,
    checked_whatsapp,
  }) => {
    if (!watchAcceptedTerms) {
      return;
    }

    setIsSubmiting(true);

    event.createOrganizer({
      checked_email,
      checked_whatsapp,
      support_email,
      support_whatsapp,
    });

    const categories = event.description?.categories.map(
      (category) => category._id
    );

    const newDateBegin = event?.data?.begin_date.split("/") ?? "";

    const dateComponentBegin = `${newDateBegin[2]}-${newDateBegin[1]}-${newDateBegin[0]}`;

    const newDateEnd = event?.data?.end_date.split("/") ?? "";

    const dateComponentEnd = `${newDateEnd[2]}-${newDateEnd[1]}-${newDateEnd[0]}`;

    const credentials = {
      name: event.information?.name,
      nickname: event.information?.nickname,
      begin_date: dateComponentBegin,
      end_date: dateComponentEnd,
      begin_hour: `${dateComponentBegin} ${event?.data?.begin_hour}`,
      end_hour: `${dateComponentEnd} ${event?.data?.end_hour}`,
      type_event: event.local?.type_event,
      address_name: event.local?.address_name ?? undefined,
      address_zipcode: event.local?.address_zipcode ?? undefined,
      address_complement: event.local?.address_complement ?? undefined,
      address_street: event.local?.address_street ?? undefined,
      address_country: "BR",
      address_district: event.local?.address_district ?? undefined,
      address_city: event.local?.address_city ?? undefined,
      address_state: event.local?.address_state ?? "",
      address_number: event.local?.address_number ?? undefined,
      address_map: event.local?.address_map ?? undefined,
      streaming_id: event.local?.streaming_id
        ? String(event.local?.streaming_id)
        : undefined,
      streaming_name: event.local?.streaming_name ?? undefined,
      streaming_url: event.local?.streaming_url ?? undefined,
      description: event.description?.description,
      categories,
      privacity: event.description?.privacity,
      age: Number(event.description?.age),
      organizer_id: organizer,
      use_terms: event.description?.use_terms,
      require_terms: Number(event.description?.require_terms),
      support_email: support_email ?? "",
      support_whatsapp: support_whatsapp ?? "",
    };

    try {
      const data = await AddEvent({ credentials });

      if (file) {
        let formData = new FormData();

        formData.append("image", file);

        await authClient.post(`/event/upload/${data?._id}`, formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        });
      }

      event.createEventResponse({
        id: data?._id,
        nickname: data?.nickname,
      });

      event.goToNextStep();

      console.warn("evento criado com sucesso");
    } catch (err: any) {
      errorNotification(
        "Ocorreu um erro ao cadastrar o evento",
        JSON.stringify(err?.response?.data)
      );
    } finally {
      setIsSubmiting(true);
    }
  };

  const checked_email = watch("checked_email");
  const checked_whatsapp = watch("checked_whatsapp");
  const watchAcceptedTerms = watch("accepted_terms");

  return (
    <Container onSubmit={handleSubmit(handleAddAboutOrganizer)}>
      <Card>
        <Title>5. Sobre o organizador</Title>
        <SubTitle>
          Selecione o organizador responsável por este evento, ou adicione um
          novo.
        </SubTitle>

        <Divider />

        <LabelTitle>Quem o é organizador do seu evento?</LabelTitle>

        <ContainerSelect>
          <LabelSelect>Organizadores cadastrados</LabelSelect>

          <Controller
            control={control}
            name="organizer"
            rules={{ required: "Salutation is required" }}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <BorderSelect>
                <StyledSelect
                  size="large"
                  labelInValue
                  bordered={false}
                  placeholder="Selecione uma opção"
                  value={value}
                  ref={ref}
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onChange={(e: any) => {
                    onChange(e.value);
                  }}
                >
                  {data?.map((organizer: any) => (
                    <Option key={organizer?._id} value={organizer?._id}>
                      {organizer?.name}
                    </Option>
                  ))}
                </StyledSelect>
              </BorderSelect>
            )}
          />

          {!!errors.organizer ? (
            <span className="error">{errors.organizer.message}</span>
          ) : null}
        </ContainerSelect>

        <SubLabelTitle>
          Não tem nenhum organizador cadastrado? Crie um agora!
        </SubLabelTitle>

        <ButtonAddImage type="button" onClick={() => {}}>
          <BiPlus size={18} color={Colors.primary100} />

          <span>Novo organizador</span>
        </ButtonAddImage>

        <Divider />

        <LabelTitle>Quais serão seus canais de suporte ao usuário?</LabelTitle>

        <SubLabelTitle>Escolha até duas opções.</SubLabelTitle>

        <ContainerCheck>
          <Space direction="horizontal">
            <Controller
              control={control}
              name="checked_whatsapp"
              render={({ field: { onChange, value, name, ref } }) => (
                <Checkbox
                  name={name}
                  onChange={(val) => {
                    onChange(val.target.checked);
                  }}
                  checked={!!value}
                  ref={ref}
                >
                  Whatsapp
                </Checkbox>
              )}
            />

            <Controller
              control={control}
              name="checked_email"
              render={({ field: { onChange, value, name, ref } }) => (
                <Checkbox
                  name={name}
                  onChange={(val) => {
                    onChange(val.target.checked);
                  }}
                  checked={!!value}
                  ref={ref}
                >
                  E-mail
                </Checkbox>
              )}
            />
          </Space>
        </ContainerCheck>

        <GridInputs>
          {checked_whatsapp ? (
            <InputForm
              {...register("support_whatsapp")}
              mask="phone"
              error={errors.support_whatsapp}
              isFull
              icon={<FaWhatsapp size={17} color={Colors.secondary40} />}
              label="Whatsapp"
            />
          ) : null}

          {checked_email ? (
            <InputForm
              {...register("support_email")}
              error={errors.support_email}
              isFull
              icon={<MdOutlineMail size={17} color={Colors.secondary40} />}
              label="E-mail"
            />
          ) : null}
        </GridInputs>

        <Divider />

        <LabelTitle>Termos e condições</LabelTitle>

        <ContainerCheckConditions>
          <Controller
            control={control}
            name="accepted_terms"
            defaultValue={false}
            render={({ field: { onChange, value, name, ref } }) => (
              <Checkbox
                name={name}
                onChange={(val) => {
                  onChange(val.target.checked);
                }}
                checked={!!value}
                ref={ref}
              />
            )}
          />

          <TextTerms>
            Ao publicar este evento, estou de acordo com os{" "}
            <a href="a">Termos de Uso</a>, com as{" "}
            <a href="a">Diretrizes de Comunidade</a> e com o{" "}
            <a href="a">Acordo de Processamento de Dados</a>, bem como declaro
            estar ciente da <a href="a">Política de Privacidade</a>, da{" "}
            <a href="a">Política de Cookies</a> e das{" "}
            <a href="a">Obrigatoriedades Legais.</a>
          </TextTerms>
        </ContainerCheckConditions>
      </Card>

      <ContainerButtons>
        <ButtonPrev
          type="button"
          disabled={!!event?.event_response?.id}
          onClick={event.goToBackStep}
        >
          <span>Voltar</span>
        </ButtonPrev>

        <ButtonNext
          htmlType="submit"
          disabled={isSubmitting || !watchAcceptedTerms}
          loading={isSubmitting}
        >
          <span>Prosseguir</span>
        </ButtonNext>
      </ContainerButtons>
    </Container>
  );
}
